<template>
  <v-container fluid>
    <v-row class="d-flex">
      <v-col
        cols="12"
        md="2"
        sm="3"
        lg="2"
      >
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="6"
        sm="5"
        lg="8"
        align-self="center"
      >
        <v-radio-group
          v-model="ord"
          class="px-10 pt-0 pt-sm-1 ml-2 mt-0 mt-sm-4 font-weight-bold"
          label="Ordenação: "
          mandatory
          row
        >
          <v-radio
            class="font-weight-regular"
            label="Mais Novas"
            color="blue darken-4"
            value="novo"
          />
          <v-radio
            class="font-weight-regular"
            label="Mais Antigas"
            color="blue darken-4"
            value="antigo"
          />
        </v-radio-group>
      </v-col>
      <v-col
        class="pl-15 pl-sm-0"
        cols="12"
        md="4"
        sm="4"
        lg="1"
        align-self="center"
      >
        <chat-ti-modal-access-vue v-if="!redirectToExternalPage()" />
      </v-col>
    </v-row>
    <v-row
      v-if="lgpd.length > 0 & ord == 'novo'"
      align="center"
      justify="start"
      class="px-12"
    >
      <v-col
        v-for="item in lgpd"
        :key="item.id"
        cols="12"
        md="3"
        sm="6"
      >
        <v-hover
          v-slot="{ hover }"
          open-delay="20"
        >
          <v-card
            height="600"
            :elevation="hover ? 16 : 0"
            color="indigo lighten-5"
            class="mx-auto d-flex flex-column"
          >
            <v-img
              class="white--text"
              max-height="200px"
              :src="item.featuredImage"
            />
            <v-card-subtitle
              class="text-uppercase pt-5 px-6 pb-0"
            >
              {{ item.createdAt | dateFormatted }}
            </v-card-subtitle>
            <v-card-text
              class="py-0 px-2 text-card"
            >
              <v-card-title
                style="word-break: break-word; line-height: 1.5rem; font-weight: bold; min-height: 130px;"
              >
                {{ item.headline | stringLimit(70) }}
              </v-card-title>
              <v-card-subtitle
                class="pt-2"
                style="min-height: 150px"
              >
                {{ item.caption | stringLimit(140) }}
              </v-card-subtitle>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ml-5 mb-5"
                outlined
                color="secondary"
                route
                :to="`${redirectToExternalPage() ? '/external' : ''}/read-lgpd/${item.slug}`"
              >
                Ver mais
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row
      v-else-if="lgpd.length > 0 & ord == 'antigo'"
      align="center"
      justify="start"
      class="px-12"
    >
      <v-col
        v-for="item in lgpd.slice().reverse()"
        :key="item.id"
        cols="12"
        md="3"
        sm="6"
      >
        <v-hover
          v-slot="{ hover }"
          open-delay="20"
        >
          <v-card
            height="600"
            :elevation="hover ? 16 : 0"
            color="indigo lighten-5"
            class="mx-auto d-flex flex-column"
          >
            <v-img
              class="white--text"
              max-height="200px"
              :src="item.featuredImage"
            />
            <v-card-subtitle
              class="text-uppercase pt-5 px-6 pb-0"
            >
              {{ item.createdAt | dateFormatted }}
            </v-card-subtitle>
            <v-card-text
              class="py-0 px-2 text-card"
            >
              <v-card-title
                style="word-break: break-word; line-height: 1.5rem; font-weight: bold; min-height: 130px;"
              >
                {{ item.headline | stringLimit(70) }}
              </v-card-title>
              <v-card-subtitle
                class="pt-2"
                style="min-height: 150px"
              >
                {{ item.caption | stringLimit(140) }}
              </v-card-subtitle>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ml-5 mb-5"
                outlined
                color="secondary"
                route
                :to="`${redirectToExternalPage() ? '/external' : ''}/read-lgpd/${item.slug}`"
              >
                Ver mais
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row
      v-else
      align="center"
      justify="center"
      class="px-12"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        <h3 class="text--disabled">
          Não há nenhum  artigo por enquanto.
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { findActiveExternalLgpd } from '@/services/lgpd-service'
import ChatTiModalAccessVue from '../components/ModalViews/ChatTiModalAccess.vue'

export default {
  name: 'LgpdGrid',
  components: {
     ChatTiModalAccessVue
  },
  props: {
    max: {
      type: Number,
      default: () => 0
    }
  },
  data: () => ({
    ord: 'novo',
    lgpd: []
  }),
  async created () {
    try {
      const data = await findActiveExternalLgpd()
      this.lgpd = data

      if (this.max > 0) {
        this.lgpd = this.lgpd.slice(0, this.max)
      }
    } catch (e) {
      this.$handleHttpError(e)
    }
  },
  methods: {
    redirectToExternalPage() {
      const EXTERNAL_ROUTE_NAME = "external.lgpd";

      const currentRoutName = this.$router?.history?.current?.name;

      const redirectToExternalComplianceRead =
        currentRoutName === EXTERNAL_ROUTE_NAME;

      return redirectToExternalComplianceRead;
    },
  },
}
</script>
